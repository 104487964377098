import React from "react"
import { withRouter } from "react-router-dom"
import { Grid, Menu, message } from "antd"
import { TeamOutlined, LogoutOutlined } from "@ant-design/icons"
import * as Commons from "common/common"

const Topbar = props => {
  const { history, currentRoom } = props
  const { SubMenu } = Menu
  const { useBreakpoint } = Grid

  const breakpoint = useBreakpoint()
  const isSmallScreen = !breakpoint.md

  const logout = () => {
    Commons.axiosInstance
      .get(Commons.apiLogout)
      .then(response => {
        if (response.status === 200) {
          message.success(Commons.successLogoutMsg)
          history.push(Commons.loginURL)
        }
      })
      .catch(error => {
        if (error.response.status === 401) {
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
  }

  const handleClick = event => {
    switch (event.key) {
      case "logout":
        logout()
        break
      default:
        break
    }
  }

  return (
    <div>
      <Menu
        mode="horizontal"
        onClick={handleClick}
        selectable={false}
        triggerSubMenuAction="click"
      >
        <Menu.Item
          className="float-left"
          icon={
            <img
              style={{ display: "inline-block", margin: "-5px 10px 0 0" }}
              src="logo-icon.svg"
              alt=""
            />
          }
        >
          {isSmallScreen ? (
            ""
          ) : (
            <span style={{ fontSize: "1.125rem" }}>会議室予約システム</span>
          )}
        </Menu.Item>
        <SubMenu
          className="float-right"
          style={{ fontSize: isSmallScreen ? "0.75rem" : "1.125rem" }}
          key="profile"
          title={currentRoom}
          icon={
            <TeamOutlined
              style={{ fontSize: isSmallScreen ? "0.75rem" : "1.5rem" }}
            />
          }
        >
          <Menu.Item
            key="logout"
            icon={<LogoutOutlined style={{ fontSize: "1rem" }} />}
            danger
          >
            ログアウト
          </Menu.Item>
        </SubMenu>
      </Menu>
    </div>
  )
}

export default withRouter(Topbar)
