import React, { useState } from "react"
import { Bottombar, Topbar } from "./components"
import { Layout, Spin } from "antd"

const Main = props => {
  const { children } = props
  const { Header, Content, Footer } = Layout

  const [isPageLoading, setIsPageLoading] = useState(false)
  const [currentRoom, setCurrentRoom] = useState("")

  const showLoadingPageSpin = () => {
    setIsPageLoading(true)
  }

  const hideLoadingPageSpin = () => {
    setIsPageLoading(false)
  }

  const childrenWithProps = React.Children.map(children, element =>
    React.cloneElement(element, {
      showLoadingPageSpin: showLoadingPageSpin,
      hideLoadingPageSpin: hideLoadingPageSpin,
      setCurrentRoom: setCurrentRoom,
    }),
  )

  return (
    <div className="flex flex-col w-full">
      <Spin spinning={isPageLoading} style={{ maxHeight: "100vh" }}>
        <Layout>
          <Layout className="site-layout min-h-screen">
            <Header className="site-layout-sub-header-background p-0">
              <Topbar currentRoom={currentRoom} />
            </Header>
            <Content className="site-layout-background bg-white">
              {childrenWithProps}
            </Content>
            <Footer className="bg-white">
              <Bottombar />
            </Footer>
          </Layout>
        </Layout>
      </Spin>
    </div>
  )
}

export default Main
