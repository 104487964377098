import React from "react"
import { Button, Divider, Menu } from "antd"
import {
  HomeFilled,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons"
import { withRouter } from "react-router-dom"
import styled from "styled-components"
// import * as Commons from "common/common"

const StyledMenu = styled(Menu)`
  border: none;
`

const Sidebar = props => {
  const { /*history, */ isCollapsed, collapseToggle } = props

  const handleClick = event => {
    switch (event.key) {
      // case 'home':
      //   history.push(Commons.settingsRoute)
      //   break
      case "collapse":
        collapseToggle()
        break
      default:
        break
    }
  }

  return (
    <div
      className="flex flex-col h-full"
      style={{ overflowX: "hidden", overflowY: "auto" }}
    >
      <div className="relative flex items-center px-1">
        <img src="logo.svg" alt="" className="mx-auto" />
      </div>
      <Divider />
      <div className="flex-grow flex-shrink">
        <StyledMenu
          onClick={handleClick}
          defaultSelectedKeys={["top"]}
          mode="inline"
          style={{
            color: "#00bcd4",
          }}
        >
          <Menu.Item
            key="top"
            icon={
              <HomeFilled
                style={{
                  marginRight: 25,
                  fontSize: 22,
                }}
              />
            }
          >
            TOP
          </Menu.Item>
        </StyledMenu>
      </div>
      <Divider style={{ margin: 0 }} />
      <Button onClick={collapseToggle}>
        {isCollapsed ? (
          <MenuUnfoldOutlined
            style={{
              fontSize: 22,
            }}
          />
        ) : (
          <MenuFoldOutlined
            style={{
              fontSize: 22,
            }}
          />
        )}
      </Button>
    </div>
  )
}

export default withRouter(Sidebar)
