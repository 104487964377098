import React, { useState, useEffect, useCallback, useRef } from "react"
import { withRouter } from "react-router-dom"
import {
  Button,
  Card,
  Carousel,
  Col,
  Divider,
  Drawer,
  message,
  notification,
  Modal,
  Row,
  Statistic,
  Tag,
} from "antd"
import {
  TeamOutlined,
  ScheduleOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons"
import moment from "moment"
import "moment/locale/ja"
import * as Commons from "common/common"

moment.locale("ja")

const Home = props => {
  const {
    history,
    showLoadingPageSpin,
    hideLoadingPageSpin,
    setCurrentRoom,
  } = props

  const isMountedRef = Commons.useIsMountedRef()
  const { Countdown } = Statistic

  const [memberModalVisible, setMemberModalVisible] = useState(false)
  const [scheduleDrawerVisible, setScheduleDrawerVisible] = useState(false)
  const [meetings, setMeetings] = useState([])
  const [meeting, setMeeting] = useState(false)
  const reminderTimer = useRef(null)

  const showMemberModal = () => {
    setMemberModalVisible(true)
  }

  const hideMemberModal = () => {
    setMemberModalVisible(false)
  }

  const showScheduleDrawer = () => {
    setScheduleDrawerVisible(true)
  }

  const hideScheduleDrawer = () => {
    setScheduleDrawerVisible(false)
  }

  const fetchMeetings = useCallback(() => {
    setMeeting(false)
    setMeetings([])

    showLoadingPageSpin()

    const getData = {
      params: {
        start: moment()
          .utc()
          .startOf("day")
          .toISOString(),
        end: moment()
          .utc()
          .endOf("day")
          .toISOString(),
      },
    }

    Commons.axiosInstance
      .get(Commons.apiGetRoomMeetings, getData)
      .then(response => {
        if (isMountedRef && response && response.data) {
          let found = false
          setCurrentRoom(response.data.room || "")

          if (response.data.meetings && response.data.meetings.length > 0) {
            const currentTime = moment()

            response.data.meetings.forEach(m => {
              if (m["日時"].value) {
                // NOW
                if (
                  currentTime.isBetween(
                    moment(m["日時"].value),
                    moment(m["日時_0"].value),
                  )
                ) {
                  Commons.backgroundMusic.currentTime = 0
                  Commons.backgroundMusic.pause()
                  notification.destroy()

                  if (!found) {
                    found = true

                    setMeeting({
                      now: true,
                      soon: false,
                      id: m["$id"].value || "",
                      name: m["文字列__1行_"].value || "",
                      start: m["日時"].value
                        ? moment(m["日時"].value).format("HH:mm")
                        : "",
                      end: m["日時_0"].value
                        ? moment(m["日時_0"].value).format("HH:mm")
                        : "",
                      company: m["ドロップダウン_1"].value || "",
                      creator: m["作成者"].value || "",
                      mMembers: m["ユーザー選択_0"].value || [],
                      oMembers: m["ユーザー選択_1"].value || [],
                      state: m.state || 0,
                    })
                  } else {
                    const tmp = {
                      now: false,
                      soon: false,
                      id: m["$id"].value || "",
                      name: m["文字列__1行_"].value || "",
                      start: m["日時"].value
                        ? moment(m["日時"].value).format("HH:mm")
                        : "",
                      end: m["日時_0"].value
                        ? moment(m["日時_0"].value).format("HH:mm")
                        : "",
                      company: m["ドロップダウン_1"].value || "",
                      creator: m["作成者"].value || "",
                      mMembers: m["ユーザー選択_0"].value || [],
                      oMembers: m["ユーザー選択_1"].value || [],
                      state: m.state || 0,
                    }

                    setMeetings(meetings => [...meetings, tmp])
                  }
                }
                // NEXT
                else if (moment(m["日時"].value).isAfter(currentTime)) {
                  if (!found) {
                    found = true

                    setMeeting({
                      now: false,
                      soon: false,
                      id: m["$id"].value || "",
                      name: m["文字列__1行_"].value || "",
                      start: m["日時"].value
                        ? moment(m["日時"].value).format("HH:mm")
                        : "",
                      end: m["日時_0"].value
                        ? moment(m["日時_0"].value).format("HH:mm")
                        : "",
                      company: m["ドロップダウン_1"].value || "",
                      creator: m["作成者"].value || "",
                      mMembers: m["ユーザー選択_0"].value || [],
                      oMembers: m["ユーザー選択_1"].value || [],
                      state: m.state || 0,
                    })
                  } else {
                    const tmp = {
                      now: false,
                      soon: false,
                      id: m["$id"].value || "",
                      name: m["文字列__1行_"].value || "",
                      start: m["日時"].value
                        ? moment(m["日時"].value).format("HH:mm")
                        : "",
                      end: m["日時_0"].value
                        ? moment(m["日時_0"].value).format("HH:mm")
                        : "",
                      company: m["ドロップダウン_1"].value || "",
                      creator: m["作成者"].value || "",
                      mMembers: m["ユーザー選択_0"].value || [],
                      oMembers: m["ユーザー選択_1"].value || [],
                      state: m.state || 0,
                    }

                    setMeetings(meetings => [...meetings, tmp])
                  }
                }

                if (
                  currentTime.isBetween(
                    moment(m["日時"].value).subtract(
                      Commons.reminderTimeInSec,
                      "seconds",
                    ),
                    moment(m["日時"].value),
                  ) &&
                  !m.state
                ) {
                  // REMINDER
                  const leftSec = moment(m["日時"].value).diff(
                    currentTime,
                    "seconds",
                  )

                  if (leftSec <= Commons.reminderTimeInSec) {
                    setMeeting(meeting => ({ ...meeting, soon: true }))

                    notification.open({
                      message: Commons.infoMeetingMsg,
                      description: (
                        <Countdown
                          title={m["文字列__1行_"].value || ""}
                          value={Date.now() + 1000 * leftSec}
                        />
                      ),
                      placement: "topRight",
                      duration: 0,
                      icon: (
                        <ClockCircleOutlined style={{ color: "#108ee9" }} />
                      ),
                    })

                    Commons.backgroundMusic.play()

                    clearTimeout(reminderTimer.current)
                    reminderTimer.current = setTimeout(() => {
                      Commons.backgroundMusic.currentTime = 0
                      Commons.backgroundMusic.pause()
                      notification.destroy()
                      fetchMeetings()
                    }, leftSec * 1000)
                  }
                } else if (
                  currentTime.isBetween(
                    moment(m["日時"].value).subtract(
                      Commons.reminderTimeInSec,
                      "seconds",
                    ),
                    moment(m["日時"].value),
                  ) &&
                  m.state &&
                  m.state === 1
                ) {
                  setMeeting(meeting => ({ ...meeting, soon: true }))
                }
              }
            })
          }
        }
      })
      .catch(error => {
        if (error.response.status === 401) {
          message.warning(Commons.errorSessionMsg)
          history.push(Commons.loginURL)
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          hideLoadingPageSpin()
        }
      })
  }, [
    isMountedRef,
    history,
    setCurrentRoom,
    showLoadingPageSpin,
    hideLoadingPageSpin,
  ])

  const meetingStart = id => {
    notification.destroy()
    Commons.backgroundMusic.currentTime = 0
    Commons.backgroundMusic.pause()

    showLoadingPageSpin()

    const postData = {
      meetingId: id,
    }

    Commons.axiosInstance
      .post(Commons.apiMeetingStart, postData)
      .then(response => {
        if (response.status === 200) {
          fetchMeetings()

          notification.open({
            message: Commons.meetingStartTitle,
            description: meeting.name,
            icon: <ClockCircleOutlined style={{ color: "#73d13d" }} />,
          })
        }
      })
      .catch(error => {
        if (error.response.status === 401) {
          message.warning(Commons.errorSessionMsg)
          history.push(Commons.loginURL)
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          hideLoadingPageSpin()
        }
      })
  }

  const meetingEnd = id => {
    showLoadingPageSpin()

    const postData = {
      meetingId: id,
    }

    Commons.axiosInstance
      .post(Commons.apiMeetingEnd, postData)
      .then(response => {
        if (response.status === 200) {
          fetchMeetings()

          notification.open({
            message: Commons.meetingEndTitle,
            description: meeting.name,
            icon: <ClockCircleOutlined style={{ color: "#ff7a45" }} />,
          })
        }
      })
      .catch(error => {
        if (error.response.status === 401) {
          message.warning(Commons.errorSessionMsg)
          history.push(Commons.loginURL)
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          hideLoadingPageSpin()
        }
      })
  }

  useEffect(fetchMeetings, [])
  useEffect(() => {
    const fetchTimer = setInterval(() => {
      if (isMountedRef.current) {
        fetchMeetings()
      }
    }, 1000 * 60 * 5)

    return () => {
      clearInterval(fetchTimer)
    }
  })

  return (
    <div>
      <div className="flex" style={{ minHeight: "80vh" }}>
        <div className="w-full m-auto">
          <Row justify="center">
            <Col xs={24} md={20}>
              {meeting ? (
                <Row gutter={[0, 16]}>
                  <Col span={24} className="text-center">
                    {meeting.now || meeting.soon ? (
                      meeting.state === 0 ? (
                        <Tag
                          color="blue"
                          style={{
                            fontSize: "58px",
                            lineHeight: "150px",
                            marginRight: 0,
                          }}
                          className="block"
                        >
                          予定の会議
                        </Tag>
                      ) : (
                        <Tag
                          color="volcano"
                          style={{
                            fontSize: "58px",
                            lineHeight: "150px",
                            marginRight: 0,
                          }}
                          className="block"
                        >
                          会議中
                        </Tag>
                      )
                    ) : (
                      <Tag
                        color="cyan"
                        style={{
                          fontSize: "58px",
                          lineHeight: "150px",
                          marginRight: 0,
                        }}
                        className="block"
                      >
                        次回の予定
                      </Tag>
                    )}
                  </Col>
                  <Col span={24}>
                    <Divider />
                  </Col>
                  <Col span={24} className="text-center">
                    <span style={{ fontSize: "38px" }}>{meeting.name}</span>
                  </Col>
                  <Col span={24} className="text-center">
                    <span style={{ fontSize: "28px" }}>{meeting.start}</span>
                    <span style={{ fontSize: "28px" }}> ~ </span>
                    <span style={{ fontSize: "28px" }}>{meeting.end}</span>
                  </Col>
                  <Col span={24} className="text-center mb-10">
                    <span style={{ fontSize: "28px" }}>
                      株式会社{meeting.company}
                    </span>
                  </Col>
                  <Col span={24} className="text-center">
                    <Button
                      type="primary"
                      size="large"
                      icon={<TeamOutlined />}
                      className="mx-1"
                      onClick={showMemberModal}
                    >
                      参加者を表示
                    </Button>
                    {meetings && meetings.length > 0 ? (
                      <Button
                        type="primary"
                        size="large"
                        icon={<ScheduleOutlined />}
                        className="mx-1"
                        onClick={showScheduleDrawer}
                      >
                        次の会議を表示
                      </Button>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col span={24} className="text-center">
                    {meeting.state === 0 && (meeting.now || meeting.soon) ? (
                      <Button
                        type="primary"
                        size="large"
                        icon={<ClockCircleOutlined />}
                        className="h-20 mx-1 px-5 text-3xl"
                        style={{
                          backgroundColor: "#73d13d",
                          borderColor: "#73d13d",
                        }}
                        onClick={() => {
                          meetingStart(meeting.id)
                        }}
                      >
                        会議開始
                      </Button>
                    ) : meeting.state === 1 && (meeting.now || meeting.soon) ? (
                      <Button
                        type="primary"
                        size="large"
                        icon={<ClockCircleOutlined />}
                        className="h-20 mx-1 px-5 text-3xl"
                        style={{
                          backgroundColor: "#ff7a45",
                          borderColor: "#ff7a45",
                        }}
                        onClick={() => {
                          meetingEnd(meeting.id)
                        }}
                      >
                        会議終了
                      </Button>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              ) : (
                <Row align="middle">
                  <Col span={24} className="text-center">
                    <Tag
                      color="green"
                      style={{ fontSize: "58px", lineHeight: "100px" }}
                    >
                      利用可能
                    </Tag>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </div>
      </div>
      <Modal
        title="参加者氏名"
        visible={memberModalVisible}
        footer={null}
        centered
        onCancel={hideMemberModal}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={8}>
            <Card
              title="予約者"
              headStyle={{
                backgroundColor: "#f6ffed",
                border: "1px solid #b7eb8f",
                color: "#73d13d",
                fontSize: "16px",
              }}
            >
              {meeting ? (
                <Row gutter={[16, 16]}>
                  <Col xs={24} className="text-center">
                    <span style={{ fontSize: "16px" }}>
                      {meeting.creator.name || ""}
                    </span>
                  </Col>
                </Row>
              ) : (
                ""
              )}
            </Card>
          </Col>
          <Col xs={24} sm={8}>
            <Card
              title="必須参加者"
              headStyle={{
                backgroundColor: "#e6f7ff",
                border: "1px solid #91d5ff",
                color: "#40a9ff",
                fontSize: "16px",
              }}
            >
              {meeting && meeting.mMembers && meeting.mMembers.length > 0 ? (
                <Row gutter={[16, 16]}>
                  {meeting.mMembers.map(mm => (
                    <Col key={mm.code || ""} xs={24} className="text-center">
                      <span style={{ fontSize: "16px" }}>{mm.name || ""}</span>
                    </Col>
                  ))}
                </Row>
              ) : (
                ""
              )}
            </Card>
          </Col>
          <Col xs={24} sm={8}>
            <Card
              title="任意参加者"
              headStyle={{
                backgroundColor: "#e6fffb",
                border: "1px solid #87e8de",
                color: "#36cfc9",
                fontSize: "16px",
              }}
            >
              {meeting && meeting.oMembers && meeting.oMembers.length > 0 ? (
                <Row gutter={[16, 16]}>
                  {meeting.oMembers.map(om => (
                    <Col key={om.code || ""} xs={24} className="text-center">
                      <span style={{ fontSize: "16px" }}>{om.name || ""}</span>
                    </Col>
                  ))}
                </Row>
              ) : (
                ""
              )}
            </Card>
          </Col>
        </Row>
      </Modal>
      <Drawer
        title=""
        placement="right"
        bodyStyle={{ backgroundColor: "#13c2c2" }}
        closable={false}
        onClose={hideScheduleDrawer}
        visible={scheduleDrawerVisible}
      >
        {meetings && meetings.length > 0 ? (
          <Carousel
            dotPosition="right"
            slidesToScroll={1}
            vertical={true}
            verticalSwiping={true}
            adaptiveHeight={true}
            className="text-white"
            style={{ backgroundColor: "#13c2c2" }}
          >
            {meetings.map((m, i) => (
              <div key={i}>
                <div
                  style={{ height: "80vh", marginRight: "30px" }}
                  className="flex items-center cursor-pointer"
                >
                  <div className="text-center">
                    <span
                      className="block font-bold"
                      style={{ fontSize: "28px" }}
                    >
                      次回の予定
                    </span>
                    <Divider className="bg-white" />
                    <span style={{ fontSize: "28px" }}>{m.start}</span>
                    <span style={{ fontSize: "28px" }}> ~ </span>
                    <span style={{ fontSize: "28px" }}>{m.end}</span>
                    <span className="block mt-4" style={{ fontSize: "22px" }}>
                      {m.name}
                    </span>
                    <span className="block mt-4" style={{ fontSize: "16px" }}>
                      株式会社{m.company}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        ) : (
          ""
        )}
      </Drawer>
    </div>
  )
}

export default withRouter(Home)
