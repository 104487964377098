import React, { useState, useEffect } from "react"
import { Col, Row } from "antd"
import moment from "moment"
import "moment/locale/ja"

moment.locale("ja")

const Bottombar = () => {
  const [timeNow, setTimeNow] = useState(moment())

  const startTimer = () => {
    const timer = setInterval(() => {
      setTimeNow(moment())
    }, 1000 * 60)

    return () => {
      clearInterval(timer)
    }
  }

  useEffect(startTimer, [])

  return (
    <Row justify="center">
      <Col>
        <span className="text-xl">
          {timeNow.format("YYYY/MM/DD (ddd) HH:mm")}
        </span>
      </Col>
    </Row>
  )
}

export default Bottombar
