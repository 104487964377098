import { useEffect, useRef } from "react"
import axios from "axios"

/* BASE URL */
export const siteURL = "https://meeting.miraic.com"
// export const siteURL = "http://localhost:8080"
export const baseURL = siteURL + "/api"
export const mediaURL = "/uploads/"

/* REDIRECT URL */
export const loginURL = "/login"
export const rootURL = "/top"

/* AUDIO */
export const backgroundMusic = new Audio("background.mp3")

/* REMINDER */
export const reminderTimeInSec = 600

export const cookieTokenName = "WildGooseChase"
export const cookieTokenEnc = "AChiponYourShoulder"

/* BACKEND API URLS */
// export const apiLogin = baseURL + "/login"
export const apiCheckSession = baseURL + "/checksess"
export const apiGetRooms = baseURL + "/roomlist"
export const apiAccessRoom = baseURL + "/roomaccess"
export const apiGetRoomMeetings = baseURL + "/apimeetings"
export const apiMeetingStart = baseURL + "/m/meetings/start"
export const apiMeetingEnd = baseURL + "/m/meetings/end"
export const apiLogout = baseURL + "/logout"

/* ROUTES */
export const loginRoute = "/login"
export const topRoute = "/top"
export const notFoundRoute = "/not-found"

/* ALERT MESSAGES */
export const errorLoginMismatchMsg = "パスワードが間違っています"
export const errorSystemMsg = "システムエラー"
export const errorSessionMsg = "もう一度ログインしてください"
export const errorPermissionMsg = "許可が足りない"
export const error404Msg =
  "申し訳ありませんが、アクセスしたページは存在しません。"
export const successLoginMsg = "ログイン成功"
export const successLogoutMsg = "ログアウト成功"
export const successCreateMsg = "登録成功"
export const successUpdateMsg = "変更成功"
export const successDeleteMsg = "削除成功"
export const infoMeetingMsg = "まもなく会議の時間です"

/* NOTIFICATION MESSAGES */
export const meetingStartTitle = "会議が始まりました"
export const meetingEndTitle = "会議は終了しました"

/* AXIOS CONFIG */
export const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: baseURL,
})

/* CHECK MOUNTED REACT */
export function useIsMountedRef() {
  const isMountedRef = useRef(null)

  useEffect(() => {
    isMountedRef.current = true
    return () => (isMountedRef.current = false)
  })

  return isMountedRef
}
