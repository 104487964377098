import React, { useState } from "react"
import { Layout, Spin } from "antd"

const Minimal = props => {
  const { children } = props
  const { Content } = Layout
  const [isPageLoading, setIsPageLoading] = useState(false)

  const showLoadingPageSpin = () => {
    setIsPageLoading(true)
  }

  const hideLoadingPageSpin = () => {
    setIsPageLoading(false)
  }

  const childrenWithProps = React.Children.map(children, element =>
    React.cloneElement(element, {
      showLoadingPageSpin: showLoadingPageSpin,
      hideLoadingPageSpin: hideLoadingPageSpin,
    }),
  )

  return (
    <div className="flex flex-col w-full">
      <Spin spinning={isPageLoading} style={{ maxHeight: "100vh" }}>
        <Layout>
          <Layout className="site-layout min-h-full">
            <Content className="site-layout-background bg-white">
              {childrenWithProps}
            </Content>
          </Layout>
        </Layout>
      </Spin>
    </div>
  )
}

export default Minimal
