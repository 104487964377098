import React, { useState, useEffect, useCallback } from "react"
import { withRouter } from "react-router-dom"
import {
  Button,
  Form,
  Input,
  message,
  notification,
  Space,
  Select,
  Typography,
} from "antd"
import { UserOutlined, LockOutlined, LoginOutlined } from "@ant-design/icons"
import * as Commons from "common/common"

const Login = props => {
  const { history, showLoadingPageSpin, hideLoadingPageSpin } = props

  const isMountedRef = Commons.useIsMountedRef()
  const [form] = Form.useForm()
  const { Text } = Typography
  const { Option } = Select

  const [isLoginLoading, setIsLoginLoading] = useState(false)
  const [rooms, setRooms] = useState([])

  const checkSession = useCallback(() => {
    Commons.backgroundMusic.currentTime = 0
    Commons.backgroundMusic.pause()
    Commons.backgroundMusic.loop = true
    notification.destroy()

    showLoadingPageSpin()

    const getData = {
      params: {
        type: "room", // USER || MASTER || ROOM
      },
    }

    Commons.axiosInstance
      .get(Commons.apiCheckSession, getData)
      .then(response => {
        history.push(Commons.rootURL)
      })
      .catch(error => {
        if (error.response.status === 401) {
          Commons.axiosInstance
            .get(Commons.apiGetRooms)
            .then(response => {
              if (isMountedRef.current && response && response.data) {
                setRooms(response.data || [])
              }
            })
            .catch(error => {
              if (error.response.status === 500) {
                message.error(Commons.errorSystemMsg)
              }
            })
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          hideLoadingPageSpin()
        }
      })
  }, [history, isMountedRef, showLoadingPageSpin, hideLoadingPageSpin])

  useEffect(checkSession, [])

  const onFinish = data => {
    setIsLoginLoading(true)

    const postData = {
      roomId: data.loginRoom,
      password: data.loginPassword,
    }

    Commons.axiosInstance
      .post(Commons.apiAccessRoom, postData)
      .then(response => {
        if (response.status === 200) {
          message.success(Commons.successLoginMsg)
          history.push(Commons.rootURL)
        }
      })
      .catch(error => {
        if (error.response.status === 401) {
          message.warning(Commons.errorLoginMismatchMsg)
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          setIsLoginLoading(false)
        }
      })
  }

  const testLogin = () => {
    setIsLoginLoading(true)

    const postData = {
      roomId: "2",
      password: "password",
    }

    Commons.axiosInstance
      .post(Commons.apiAccessRoom, postData)
      .then(response => {
        if (response.status === 200) {
          message.success(Commons.successLoginMsg)
          history.push(Commons.rootURL)
        }
      })
      .catch(error => {
        if (error.response.status === 401) {
          message.warning(Commons.errorLoginMismatchMsg)
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          setIsLoginLoading(false)
        }
      })
  }

  return (
    <div className="flex h-screen">
      <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/3 p-5 m-auto">
        <div className="flex mb-10">
          <img src="logo.svg" alt="" className="mx-auto w-3/4" />
        </div>
        <div className="text-center mb-10">
          <Text style={{ fontSize: 20 }}>会議室予約システム</Text>
        </div>
        <Form name="loginForm" onFinish={onFinish} size="large" form={form}>
          {/* <Form.Item
            name="loginId"
            rules={[
              {
                required: true,
                message: "キントーンIDを入力してください",
              },
            ]}
          >
            <Input
              name="loginId"
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="キントーンID"
            />
          </Form.Item> */}
          <Form.Item
            name="loginRoom"
            rules={[
              {
                required: true,
                message: "会議室を選択してください",
              },
            ]}
          >
            <Select
              placeholder="会議室を選択してください"
              prefix={<UserOutlined className="site-form-item-icon" />}
            >
              {rooms
                ? rooms.map(room => (
                    <Option key={room.id} value={room.id}>
                      {room.name}
                    </Option>
                  ))
                : ""}
            </Select>
          </Form.Item>
          <Form.Item
            name="loginPassword"
            rules={[
              {
                required: true,
                message: "パスワードを入力してください",
              },
            ]}
          >
            <Input.Password
              name="loginPassword"
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="パスワード"
            />
          </Form.Item>
          <Form.Item className="text-center">
            <Space direction="vertical">
              <Button
                type="primary"
                icon={<LoginOutlined />}
                htmlType="submit"
                loading={isLoginLoading}
              >
                ログイン
              </Button>
              <Button
                type="primary"
                className="login-form-button"
                onClick={testLogin}
                loading={isLoginLoading}
              >
                テストログイン
              </Button>
            </Space>
          </Form.Item>
        </Form>
        <div className="flex mb-10"></div>
      </div>
    </div>
  )
}

export default withRouter(Login)
